// Builder.io integration. Enables specific pages to be built visually using
// drag-and-drop.
//
// https://www.builder.io/m/developers
import { Builder, builder, withChildren } from '@builder.io/react';
import { accordionConfig } from '@builder.io/widgets/dist/lib/components/Accordion.config';
import { carouselConfig } from '@builder.io/widgets/dist/lib/components/Carousel.config';
import { masonryConfig } from '@builder.io/widgets/dist/lib/components/Masonry.config';
import { tabsConfig } from '@builder.io/widgets/dist/lib/components/Tabs.config';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';

const { publicRuntimeConfig } = getConfig();
builder.init(publicRuntimeConfig.BUILDER_API_KEY);

Builder.registerComponent(
  dynamic(() =>
    import('@builder.io/widgets/dist/lib/components/Accordion').then(
      (mod) => mod.AccordionComponent
    )
  ),
  accordionConfig
);

Builder.registerComponent(
  dynamic(() =>
    import('@builder.io/widgets/dist/lib/components/Carousel').then(
      (mod) => mod.CarouselComponent
    )
  ),
  carouselConfig
);

Builder.registerComponent(
  dynamic(() =>
    import('@builder.io/widgets/dist/lib/components/Masonry').then(
      (mod) => mod.MasonryComponent
    )
  ),
  masonryConfig
);

Builder.registerComponent(
  dynamic(() =>
    import('@builder.io/widgets/dist/lib/components/Tabs').then(
      (mod) => mod.TabsComponent
    )
  ),
  tabsConfig
);

Builder.registerComponent(
  dynamic(() => import('src/modules/About/Cover').then((mod) => mod.Cover)),
  {
    name: 'AboutCover',
    description: 'About Page - Cover',
  }
);

Builder.registerComponent(
  dynamic(() => import('src/modules/About/Vision').then((mod) => mod.Vision)),
  {
    name: 'AboutVision',
    description: 'About Page - Vision',
  }
);

Builder.registerComponent(
  dynamic(() => import('src/modules/About/Values').then((mod) => mod.Values)),
  {
    name: 'AboutValues',
    description: 'About Page - Values',
  }
);

Builder.registerComponent(
  dynamic(() =>
    import('src/modules/About/Founders').then((mod) => mod.Founders)
  ),
  {
    name: 'AboutFounders',
    description: 'About Page - Founders',
  }
);

Builder.registerComponent(
  dynamic(() => import('src/modules/About/History').then((mod) => mod.History)),
  {
    name: 'AboutHistory',
    description: 'About Page - History',
  }
);

Builder.registerComponent(
  dynamic(() =>
    import('src/modules/About/ServicesAndSolutions').then(
      (mod) => mod.ServicesAndSolutions
    )
  ),
  {
    name: 'AboutServicesAndSolutions',
    description: 'About Page - Services and Solutions',
  }
);

Builder.registerComponent(
  dynamic(() =>
    import('src/modules/About/Partners').then((mod) => mod.Partners)
  ),
  {
    name: 'AboutPartners',
    description: 'About Page - Partners',
  }
);

Builder.registerComponent(
  dynamic(() => import('src/modules/About/Offices').then((mod) => mod.Offices)),
  {
    name: 'AboutOffices',
    description: 'About Page - Offices',
  }
);

Builder.registerComponent(
  dynamic(() =>
    import('src/modules/About/WorkWithUs').then((mod) => mod.WorkWithUs)
  ),
  {
    name: 'AboutWorkWithUs',
    description: 'About Page - Work With Us',
  }
);

Builder.registerComponent(
  dynamic(() => import('src/modules/Homepage/Hero').then((mod) => mod.Hero)),
  {
    name: 'HomeHero',
    description: 'Home Page - Hero',
    inputs: [
      {
        name: 'title',
        type: 'string',
        defaultValue:
          'Empowering people and organizations to realize their human potential',
        required: true,
      },
      {
        name: 'description',
        type: 'string',
        defaultValue:
          'Glints seeks to enable the 120 million professionals in the region to find jobs and grow their careers, and empower organizations and employers to recruit the right talent and build great teams from anywhere in Southeast Asia.',
        required: true,
      },
      {
        name: 'image',
        type: 'file',
        allowedFileTypes: ['jpeg', 'png', 'webp', 'gif', 'pdf', 'svg'],
        required: true,
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() =>
    import('src/modules/Homepage/JobSelector').then((mod) => mod.JobSelector)
  ),
  {
    name: 'HomeJobForSeekers',
    description: 'Home Page - JobSelector',
    inputs: [
      {
        name: 'opportunities',
        type: 'list',
        subFields: [
          {
            name: 'name',
            type: 'string',
            defaultValue: 'Singapore',
            localized: true,
            required: true,
          },
          {
            name: 'code',
            type: 'string',
            defaultValue: 'SG',
            enum: ['SG', 'ID', 'VN', 'TW', 'MY'],
            required: true,
          },
          {
            name: 'amountOfJobs',
            type: 'string',
            helperText: 'Defines amount of jobs shown on UI',
            required: true,
          },
          {
            name: 'image',
            type: 'file',
            allowedFileTypes: ['jpeg', 'png', 'webp', 'gif', 'pdf', 'svg'],
            required: true,
          },
          {
            name: 'hasApp',
            type: 'boolean',
            helperText: 'Defines whether to show download app button',
          },
          {
            name: 'url',
            type: 'url',
            helperText: 'Defines url for Job Marketplace',
          },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import('glints-aries').then((mod) => withChildren(mod.Button))),
  {
    name: 'AriesButton',
    description: 'Glints Aries - Button',
    defaultChildren: [
      {
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'Button' } },
      },
    ],
    inputs: [
      {
        name: 'variant',
        type: 'string',
        enum: [
          'link',
          'solid-white',
          'solid-blue',
          'yellow',
          'ghost',
          'white-grey',
        ],
        defaultValue: 'solid-blue',
      },
      {
        name: 'block',
        type: 'boolean',
        defaultValue: false,
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import('src/components/Footer/FooterPsychFlat')),
  {
    name: 'Footer',
    description: 'Footer',
  }
);
